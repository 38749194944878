import { AppBar, Toolbar, Typography } from '@mui/material/'
import * as React from 'react'

export function Header() {
  return (
    <AppBar position="static">
      <Toolbar>
        <img src="./img/mc.48x48.color.png" alt='BMC' />
        <Typography variant="h5" width="100%">
          &nbsp;
          Boomza Memo Cards
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
