import React from 'react'
import { Header } from './components'
import { HomePage } from './HomePage'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { colors } from '@mui/material'

function App() {
  const currentColor = colors.teal
  const theme = createTheme({
    typography: {
      fontFamily: 'Verdana',
    },
    palette: {
      background: {
        paper: currentColor[100],
        default: currentColor[800]
      },
      primary: {
        main: currentColor[900]
      },
      secondary: {
        main: currentColor[500]
      }
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <HomePage />
    </ThemeProvider>
  )
}

export default App
