import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'

export const Privacy: React.FC<{ isOpen: boolean; onClick: () => void }> = ({
  isOpen,
  onClick
}) => {
  const descriptionElementRef = React.useRef<HTMLElement>(null)
  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Privacy policy for Boomza Memo Cards<sup>&copy;</sup></DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Typography variant="body1">
            This privacy policy describes how Memo Cards collects, uses, and
            protects your personal information when you use our mobile
            educational application. By downloading and using our application,
            you agree to this privacy policy and consent to our data practices.
          </Typography>

          <Typography variant="h5">What information do we collect?</Typography>

          <Typography variant="body1">
            We collect information that you provide to us when you create an
            account, such as your name, email address, password, and profile
            picture. We also collect information that you generate when you use
            our application, such as your progress, scores, preferences,
            feedback, and ratings. We may also collect information from
            third-party services that you connect to our application, such as
            your social media accounts or other educational platforms.
          </Typography>

          <Typography variant="h5">How do we use your information?</Typography>

          <Typography variant="body1">
            We use your information to provide and improve our application and
            its features, such as personalizing your learning experience,
            recommending relevant content, rewarding your achievements, and
            enhancing your performance. We also use your information to
            communicate with you, such as sending you notifications, updates,
            newsletters, surveys, and promotional offers. We may also use your
            information to analyze and measure how our application is used and
            how it can be improved.
          </Typography>

          <Typography variant="h5">
            How do we protect your information?
          </Typography>

          <Typography variant="body1">
            We take reasonable measures to protect your information from
            unauthorized access, use, disclosure, modification, or destruction.
            We use encryption, firewalls, authentication, and other security
            technologies to safeguard your information. We also limit access to
            your information to our employees, contractors, and agents who need
            it to perform their duties for us. However, we cannot guarantee that
            your information is completely secure and we are not responsible for
            any breach of security that is beyond our control.
          </Typography>

          <Typography variant="h5">
            How do we share your information?
          </Typography>

          <Typography variant="body1">
            We do not sell, rent, or trade your information with any third
            parties for their own purposes. We may share your information with
            our affiliates, partners, service providers, or other trusted
            entities that help us provide and improve our application and its
            features. We may also share your information with law enforcement or
            other authorities if required by law or to protect our rights or
            property.
          </Typography>

          <Typography variant="h5">
            How can you access and control your information?
          </Typography>

          <Typography variant="body1">
            You can access and update your account information at any time
            through the settings menu in our application. You can also delete
            your account and all associated information by contacting us at
            support@memocards.com. You can opt out of receiving communications
            from us by following the unsubscribe link or instructions in each
            email or message. You can also change your notification preferences
            in the settings menu in our application.
          </Typography>

          <Typography variant="h5">
            How do we update this privacy policy?
          </Typography>

          <Typography variant="body1">
            We may update this privacy policy from time to time to reflect
            changes in our data practices or applicable laws. We will notify you
            of any material changes by posting a notice on our website or in our
            application or by sending you an email. Your continued use of our
            application after such notice constitutes your acceptance of the
            updated privacy policy.
          </Typography>

          <Typography variant="h5">How can you contact us?</Typography>

          <Typography variant="body1">
            If you have any questions or concerns about this privacy policy or
            our data practices, please contact us at boomza@gmail.com or write
            to us at Memo Cards, 8 Broad St., Suite 108, Red Bank, NJ, USA.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
